<template>
  <div>
    <helloWorld />
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>